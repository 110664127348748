import logo from './Logo.png'

function Navbar(){
    return(
        <header>
        <div class="logo">
            <img src={logo} alt="Logo"/>
        </div>
        <nav id="home">
            <ul>
                <li><a href="#home">Home</a></li>
                <li><a href="#about">About</a></li>
                <li><a href="#contact" id="contactinfo">Contact</a></li>
            </ul>
        </nav>
    </header>
    )
}

export default Navbar