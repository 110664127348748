import Image from './Building.png';
import './App.css';

import Navbar from './Navbar.jsx'
import About from './About.jsx'
import Main from './Main.jsx'
import Contact from './Contact.jsx'

function App() {
  return (
    <>
      <Navbar/>
      <div className="grid">
        <hr className='head'/>
        <About/>
        <div className="office"><img src={Image} alt="Our Office"/></div>
        <Main/>
        <hr className='hr'/>
        <Contact/>
      </div>
    </>
  );
}

export default App;
