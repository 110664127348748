function Main(){
    return(
        <div className="main">
            <h1>Timmy&trade; Office Building</h1>
            <p>In Evercrest, our modern office fosters collaboration with sleek architecture, state-of-the-art facilities, and ergonomic workspaces. From the welcoming reception to advanced meeting rooms, every detail is curated for functionality and aesthetics.</p>
            <img></img>
        </div>
    )
}

export default Main