function About() {
    return(
        <div className="card" id="about">
            <h1>🌐 Timmy&trade; Where Innovation Meets Opportunity 🌐</h1>
            <p>Welcome to the dynamic universe of TimmyTM (TTM), where cutting-edge technology converges with boundless financial possibilities. I'm thrilled to introduce you to the essence of TimmyTM, the crypto sensation that's redefining the landscape of decentralized finance.</p>
            <br />
            <p>TimmyTM was born out of a vision to create a cryptocurrency that transcends traditional boundaries, offering a seamless and empowering experience for users worldwide. We're not just a digital currency; we're a catalyst for the future, built on the pillars of innovation, security, and community engagement.</p>
            <br />
            <p>TimmyTM is now seamlessly integrated with the Binance Smart Chain (BSC), amplifying the efficiency and accessibility of our revolutionary crypto. Brace yourself for rapid, cost-effective transactions as TimmyTM on BSC delivers unparalleled speed and minimal fees.</p>
        </div>
    )
}

export default About