function Contact(){
    return(
        <>
            <div className="contactbtn" id="contact">
                <h1>Need Help?</h1>
                <p>No matter the reason, I'm excited to awnser any question! Your message will receive a prompt response as soon as it reaches me. Just a click on the button, and our communication will begin shortly!</p>
            </div>
            <div className="button"><a href="mailto:timmytimmyfied@proton.me">Get In Touch With Me</a></div>
        </>
    )
}

export default Contact